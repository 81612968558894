<template>
  <div>
    <div class="row">
      <div class="col">
        <button class="btn btn-dark-blue btn-sm" @click="showWebhookForm = true">New Webhook</button>
        <span class="pl-2">Send new contact data to Zapier and other applications.</span>
      </div>
    </div>
    <div v-if="user && webhooks.length" class="mt-3">
      <div v-for="webhook in webhooks" :key="webhook.id">
        <span class="font-weight-bold">{{webhook.name}}</span><button class="btn btn-link p-1" @click="showEditForm(webhook)"><Feather type="edit" class="ml-2" /></button><button class="btn btn-link p-1" @click="askDelete(webhook)"><Feather type="trash" class="text-danger" /></button>
      </div>
    </div>
    <b-modal v-model="showWebhookForm" title="Create New Webhook" title-class="text-center" :hide-footer="true" size="lg" ok-variant="dark" @close="clearForm" >
      <ValidationObserver
          v-slot="{ handleSubmit, invalid, touched }"
          ref="webhookForm"
      >
        <form
            class="authentication-form"
            @submit.prevent="handleSubmit(postWebhook)"
        >
          <div class="row">
            <div class="col-md-4">
              <TextInput
                  v-model="form.webhook_name"
                  name="name"
                  rules="required"
                  label="Webhook Name"
                  type="text"
              />
            </div>
            <div class="col-md-8">
              <TextInput
                  v-model="form.webhook_url"
                  name="url"
                  rules="required"
                  label="Webhook URL"
                  type="text"
              />
            </div>
          </div>
          <div class="row">
              <div class="col">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                      id="webhook-checkbox"
                      v-model="form.webhook_fields"
                      :options="form.webhook_field_options"
                      size="sm"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-1"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
          </div>
          <b-form-group class="text-right">
            <b-button
                variant="primary"
                class="btn-dark-blue"
                :disabled="loadingWebhook || (invalid && touched)"
                type="submit"
            >
              <b-spinner v-if="loadingWebhook" small />
              <span v-else>Update</span>
            </b-button>
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showDeleteModal" title="Delete Webhook">
      <p v-if="selectedWebhook"
      >Are you sure you want to delete webhook "{{
          selectedWebhook.name
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
        >Cancel</b-button
        >
        <b-button variant="danger" @click="deleteWebhook">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  props:{
    hooktype:{
      type: [String,null],
      default:null
    }
  },
  data() {
    return {
      loadingWebhook:false,
      showWebhookForm: false,
      showDeleteModal:false,
      selectedWebhook:{},
      user: {
        agency: {},
      },
      form:{
        webhook_id:null,
        setting_form:'webhook',
        webhook_name:'',
        webhook_url:'',
        webhook_fields: [],
        webhook_field_options: null,
        webhook_product:this.hooktype
      },
      password: {
        password: '',
        password_confirmation: '',
      },
    }
  },
  computed:{
    webhooks(){
      return this.user.agency.webhooks ? this.user.agency.webhooks.filter(obj => {
        return obj.product === this.hooktype;
      }) : [];
    }
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    if(this.hooktype === 'leadmachine'){
      this.form.webhook_field_options = [
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Opt-in Email', value: 'email' },
        { text: 'Opt-in Phone', value: 'phone_number' },
        { text: 'Business Name', value: 'business_name' },
        { text: 'Business Phone', value: 'business_phone' },
        { text: 'Business Website', value: 'business_website' },
        { text: 'Business Address', value: 'address' },
        { text: 'Short URL', value: 'short_url' },
        { text: 'Google Review Link', value: 'google_review_link' },
        { text: 'Google Maps URL', value: 'google_maps_url' },
        { text: 'Place ID', value: 'place_id' },
        { text: 'Categories', value: 'types' },
        { text: 'Latitude', value: 'lat' },
        { text: 'Longitude', value: 'lng' },
        { text: 'Status', value: 'status' },
        { text: 'Unique ID', value: 'unique_id' },
        { text: 'Rating', value: 'rating' },
        { text: 'Review Count', value: 'review_count' },
      ]
    }else{
      this.form.webhook_field_options = [
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Opt-in Email', value: 'email' },
        { text: 'Opt-in Phone', value: 'phone_number' },
        { text: 'Business Name', value: 'business_name' },
        { text: 'Business Phone', value: 'business_phone' },
        { text: 'Business Website', value: 'business_website' },
        { text: 'Business Address', value: 'address' },
        { text: 'Short URL', value: 'short_url' },
        { text: 'Google Review Link', value: 'google_review_link' },
        { text: 'Google Maps URL', value: 'google_maps_url' },
        { text: 'Originating URL', value: 'origin' },
      ]
    }

  },
  methods: {
    askDelete(webhook){
      this.showDeleteModal = true
      this.selectedWebhook = webhook
    },
    showEditForm(webhook){
      this.showWebhookForm = true;
      this.form.webhook_id = webhook.id
      this.form.webhook_name = webhook.name
      this.form.webhook_url = webhook.url
      this.form.webhook_fields = webhook.fields
    },
    clearForm(){
      this.showWebhookForm = false;
      this.form.webhook_id = null;
      this.form.webhook_name = '';
      this.form.webhook_url = '';
      this.form.webhook_fields = [];
      this.selectedWebhook = {};
    },
    deleteWebhook(){
      this.showDeleteModal = false
      this.loadingWebhook = true

      this.$store
          .dispatch('agency/editSettings', {webhook_id:this.selectedWebhook.id,setting_form:'webhook_delete'})
          .then((resp) => {
            this.loadingWebhook = false
            this.clearForm()
            this.user = resp.data;
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.errors) {
              this.$refs.webhookForm.setErrors(err.response.data.errors)
            }
            this.loadingWebhook = false
          })
    },
    postWebhook() {
      this.loadingWebhook = true

      this.$store
          .dispatch('agency/editSettings', this.form)
          .then((resp) => {
            this.loadingWebhook = false
            this.clearForm()
            console.log(resp.data);
            this.user.agency.webhooks = resp.data.agency.webhooks;
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.errors) {
              this.$refs.webhookForm.setErrors(err.response.data.errors)
            }
            this.loadingWebhook = false
          })
    },
  },
}
</script>
<style lang="scss">
  #webhook-checkbox{
    .custom-control{
      margin-bottom: 15px;
      width: 20%;
    }
  }
  @media screen and (max-width:991px){
    #webhook-checkbox {
      .custom-control {
        width: 44%;
      }
    }
  }
</style>
